



































































import { defineComponent, ref, useContext } from '@nuxtjs/composition-api';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { SfInput, SfButton } from '@storefront-ui/vue';
import { useUiNotification } from '~/composables';

import type { SubmitEventPayload } from '~/modules/customer/types/form';
import type { PasswordResetFormFields } from '~/modules/customer/pages/MyAccount/MyProfile/types';

export default defineComponent({
  components: {
    SfInput,
    SfButton,
    ValidationProvider,
    ValidationObserver,
  },

  setup(_, { emit }) {
    const { app: { i18n } } = useContext();
    const getInitialForm = () : PasswordResetFormFields => ({
      currentPassword: '',
      newPassword: '',
      repeatPassword: '',
    });
    const {
      send: sendNotification,
    } = useUiNotification();

    const form = ref(getInitialForm());

    const submitForm = (resetValidationFn: () => void) => () => {
      const onComplete = () => {
        form.value = getInitialForm();
        sendNotification({
          id: Symbol('password_updated'),
          message: i18n.t('The user password was changed successfully updated!') as string,
          type: 'success',
          icon: 'check',
          persist: false,
          title: 'User Account',
        });
        resetValidationFn();
      };

      const onError = (message: string) => {
        sendNotification({
          id: Symbol('password_not_updated'),
          message: i18n.t(message) as string,
          type: 'danger',
          icon: 'cross',
          persist: false,
          title: 'User Account',
        });
      };

      const eventPayload : SubmitEventPayload<PasswordResetFormFields> = { form: form.value, onComplete, onError };

      emit('submit', eventPayload);
    };

    return {
      form,
      submitForm,
    };
  },
});
