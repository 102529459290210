






















































































































import { extend } from 'vee-validate';
import { required, min, confirmed } from 'vee-validate/dist/rules';
import { SfIcon, SfSidebar } from '@storefront-ui/vue';
import { computed, defineComponent, ref } from '@nuxtjs/composition-api';
import { useUser } from '~/modules/account/composables/useUser';
import {
  customerPasswordRegExp,
  invalidPasswordMsg,
} from '~/modules/account/helpers/passwordValidation';
import ProfileUpdateForm from '~/modules/account/pages/MyAccount/MyProfile/ProfileUpdateForm.vue';
import PasswordResetForm from '~/modules/account/pages/MyAccount/MyProfile/PasswordResetForm.vue';
import type {
  OnFormComplete,
  OnFormError,
  SubmitEventPayload,
} from '~/modules/account/types/form';
import type {
  ProfileUpdateFormFields,
  PasswordResetFormFields,
} from '~/modules/account/pages/MyAccount/MyProfile/types';
import useUiNotification from '~/composables/useUiNotification';

require('../../styles/shared.scss');

extend('required', {
  ...required,
  message: 'This field is required',
});

extend('min', {
  ...min,
  message: 'The field should have at least {length} characters',
});

extend('password', {
  message: invalidPasswordMsg,
  validate: (value: string) => customerPasswordRegExp.test(value),
});

extend('confirmed', {
  ...confirmed,
  // eslint-disable-next-line quotes
  message: "Passwords don't match",
});

export default defineComponent({
  name: 'MyProfile',
  components: {
    SfIcon,
    SfSidebar,
    ProfileUpdateForm,
    PasswordResetForm,
  },

  setup() {
    const {
      changePassword,
      loading,
      updateUser,
      error,
      isAuthenticated,
      user,
    } = useUser();
    const isSubscribed = computed(() => !!user.value.is_subscribed);
    const sideVisible = ref<boolean>(false);
    const { send: sendNotification } = useUiNotification();

    const formHandler = async <T extends () => Promise<unknown>>(
      onSubmit: T,
      onComplete: OnFormComplete,
      onError: OnFormError
    ) => {
      await onSubmit();
      const actionErr = error.value.changePassword || error.value.updateUser;
      if (actionErr) {
        onError(actionErr.toString());
      } else {
        onComplete();
      }
    };

    const updatePersonalData = ({
      form,
      onComplete,
      onError,
    }: SubmitEventPayload<ProfileUpdateFormFields>) =>
      formHandler(async () => updateUser({ user: form }), onComplete, onError);

    const updatePassword = ({
      form,
      onComplete,
      onError,
    }: SubmitEventPayload<PasswordResetFormFields>) =>
      formHandler(
        async () =>
          changePassword({
            current: form.currentPassword,
            new: form.newPassword,
          }),
        onComplete,
        onError
      );

    const saveNewsLetterForm = async () => {
      if (isAuthenticated.value && !!user.value.email) {
        try {
          await updateUser({ user: { is_subscribed: !isSubscribed.value } });
          // eslint-disable-next-line @typescript-eslint/no-shadow
        } catch (error) {
          sendNotification({
            id: Symbol('user_updated'),
            message: error as string,
            type: 'danger',
            icon: 'cross',
            persist: false,
            title: 'Error Happened',
          });
        }
      }
    };

    return {
      loading,
      isSubscribed,
      sideVisible,
      updatePersonalData,
      updatePassword,
      saveNewsLetterForm,
    };
  },
});
